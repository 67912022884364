.ExamplesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 10px;
}

@media screen and (max-width: 1640px) {
  .ExamplesContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .ExamplesContainer {
    grid-template-columns: 1fr;
  }
}