.new-example h2 {
    font-size: 2.5rem;
}

.new-example h4 {
    font-size: 1.5rem;
}

.new-example {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #242424;
    color: #fff;
}

.new-example label {
    width: 100%;
    text-align: center;
    margin: 10px auto;
    color: bisque;
    font-size: 1.1REM;
}

.new-example input {
    max-width: 500px;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #242424;
    color: #fff;
}

.new-example button {
    max-width: 300px;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #242424;
    color: #fff;
    transition: all 0.3s ease;
    font-weight: bold;
}

.new-example button:hover {
    background-color: lightcoral;
    color: white;
}

.ancho-alto {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.ancho-alto span {
    margin: 0 20px;
}

@media screen and (max-width: 768px) {
    .ancho-alto {
        flex-direction: column;
        width: 100%;
    }

    .new-example input, .new-example button, .ancho-alto input {
        width: 90%;
    }

}