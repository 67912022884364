p {
    margin: 0;
}

h2, h4 {
    width: 100%;
    text-align: center;
    margin: 10px auto;
    color: lightcoral;
    font-size: 1.5REM;
}

h4 {
    color : lightsalmon;
    font-size: 1.1REM;
    max-width: 150px;
}

.example {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #242424;
    color: #fff;
}

.example__original, .example__editions {
    width: 100%;
}

.example__original__data {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 0;
}

.example__limited_edition {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.example__original__data > * {
    margin: 0 30px;
    width: 33%;
    text-align: center;
}

.example__original__data > div > p {
    margin: 5px auto;
}

/* Select the first div inside .example__original__data */
.example__original__data > div > :first-child {
    color: bisque;
    width: 50%;
}

@media screen and (max-width: 768px) {
    .example {
        width: 100%;
        padding: 10px;
    }
    .example__limited_edition {
        flex-direction: column;
        padding: 15px 0;
    }
    .example__limited_edition  > h4 {
        max-width: 20vw;
    }
    .example__original__data > * {
        margin: 0
    }

    .example__original__data > div > p {
        margin: 10px auto!important;
    }
}